
import React from 'react'
import LandingPageLayout from '../components/Layouts/LandingPage'
import SectionContainer from '../components/UI/Containers/SectionContainer'
import OutlineButton from './../components/UI/Buttons/OutlineButton/OutlineButton'
import * as styles from './../styles/pageSpecific/landings.module.scss'

const NotFoundPage = () => {
    const pageInfo = {
        pageType:'interior',
        title1: '404:',
        title2: 'Page Not Found',
        breadcrumbs: [''],
        ctaButton: false,
        ctaButtonData:{
            text: '',
            url: ''
          }
    }
 

    return(
        <LandingPageLayout pageData={pageInfo}>
           <div className="content">
                <SectionContainer id='' color='' type='contained' classes={['inlineChildren_column', 'alignChildrenCenter']}>
                    <h1 className={styles.large}>404</h1>
                    <h2>Page Not Found </h2>
                </SectionContainer>
                <SectionContainer id='notfound_content' color='' type='contained' classes={['inlineChildren_column', 'alignChildrenCenter']}>
                      <p>Sorry! We couldn't find the page you were looking for.</p>
                      <OutlineButton 
                        type='white'
                        buttonData={{
                          text: 'Go Back Home',
                          url:'/'
                        }}/>
                </SectionContainer>
            </div> 
        </LandingPageLayout>
    )
}

export default NotFoundPage